.Join-container {
  display: flex;
  gap: 10rem;
  padding: 0 2rem;
}
.l-side {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.l-side > hr {
  position: absolute;
  border: 2px solid var(--orange);
  width: 10.5rem;
  border-radius: 20%;
  margin: -10px 0;
}
.r-side{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap: 3rem;
    padding: 1rem 2rem;
    background: gray;
}
.email-container>input{
    border: none;
    outline: none;
    background: transparent;
    color: var(--lightgray);
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    color: white;
    background: var(--orange);
}


@media only screen and (max-width: 768px){
  .Join-container{
    flex-direction: column;
    gap: 2rem;
  }
  .l-side{
    font-size: x-large;
    flex-direction: column;
  }
  .r-side{
    padding: 2rem;
  }
}
.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;

}
.h-menu{
    list-style: none;
    text-transform: capitalize;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
    color: white;

}
.h-menu li:hover{
    cursor: pointer;
    color: var(--orange);
}
@media only screen and (max-width: 768px) {
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .h-menu{
        background: var(--appColor);
        padding: 2rem;
        flex-direction: column;
    }
}
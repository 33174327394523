.Programs {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 0 2rem;
}
.program-header {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 3rem;
  text-transform: uppercase;
  color: white;
  justify-content: center;
  font-style: italic;
}
.programCat {
  display: flex;
  gap: 1rem;
}
.catogery {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  color: white;
  background-color: var(--gray);
  padding: 2rem;
}
.catogery > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
}
.catogery > :nth-child(2) {
    font-size: 1rem;
    font-weight: bold;
}
.catogery > :nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
}
.join-now{
   display: flex; 
   align-items: center;
   gap: 2rem;
}
.join-now>img{
    width: 1rem;
}
.catogery:hover{
    cursor: pointer;
    background: var(--planCard);
}

@media only screen and (max-width: 768px) {
  .program-header {
    flex-direction: column;
    gap: 2rem;
    font-size: x-large;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }
  .programCat{
    flex-direction: column;
  }
}

.Plans-container {
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding: 0 2rem;
  position: relative;
  margin-top: -2rem;
}

.plan-header {
  display: flex;
  gap: 5rem;
  font-weight: bold;
  font-size: 2.5rem;
  text-transform: uppercase;
  color: white;
  justify-content: center;
  font-style: italic;
  letter-spacing: 2px;
}
.pkgs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-top: 4rem;
}
.pkg {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: var(--caloryCard);
  color: white;
  padding: 1.5rem;
  width: 15rem;
}
.pkg:nth-child(2) {
  background: var(--planCard);
  transform: scale(1.1);
}
.pkg > svg {
  fill: var(--orange);
  width: 2rem;
  height: 2rem;
}
.pkg > :nth-child(2) {
  font: size 1rem;
  font-weight: bold;
}
.pkg > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}
.pkg > :nth-child(5) {
  font-size: 0.8rem;
  cursor: pointer;
}
.pkg > :nth-child(6) {
  cursor: pointer;
}
.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.feature {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.feature img {
  width: 1rem;
}

.pkgs > :nth-child(2) > svg {
  fill: white;
}
.pkgs > :nth-child(2) > button {
  color: var(--orange);
}

.blur-l{
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 5rem;
}


@media only screen and (max-width: 768px){
  .plan-header{
    flex-direction: column;
    gap: 2rem;
    font-size: x-large;
    justify-content: center;
    align-items: center;
    font-style: italic;
  }
  .pkgs{
    flex-direction: column;
  }
  .pkg:nth-child(2){
    transform: none;
  }
}

